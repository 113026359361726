import { Component } from '@angular/core';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatAnchor, MatButton } from '@angular/material/button';

@Component({
  selector: 'app-support-dialog',
  templateUrl: './support.dialog.component.html',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogTitle,
    TranslateModule,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    MatAnchor,
  ],
})
export class SupportDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<SupportDialogComponent>,
    private translate: TranslateService
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }
}
