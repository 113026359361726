import { ErrorHandler } from '@angular/core';
import { environment } from '../../../../environments/environment';

import {
  captureException as sentryCaptureException,
  captureMessage as sentryCaptureMessage,
  createErrorHandler,
  ErrorEvent,
  httpClientIntegration,
  init as sentryInit,
  setUser as sentrySetUser,
} from '@sentry/angular';
import { User } from '../../../entities/user.model';
import { CaptureContext, SeverityLevel } from '@sentry/types';

export class MonitoringService {
  private static isDebugMode(): boolean {
    return new URLSearchParams(window.location.search).get('debug') === 'true';
  }

  private static enrichEventWithDebugInfo(event: ErrorEvent): ErrorEvent {
    if (!MonitoringService.isDebugMode()) {
      return event;
    }

    const debugInfo = {
      userAgent: window.navigator.userAgent,
      windowSize: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      timestamp: new Date().toISOString(),
      url: window.location.href,
      memoryInfo: (window.performance as any).memory
        ? {
            jsHeapSizeLimit: (window.performance as any).memory.jsHeapSizeLimit,
            totalJSHeapSize: (window.performance as any).memory.totalJSHeapSize,
            usedJSHeapSize: (window.performance as any).memory.usedJSHeapSize,
          }
        : 'Not available',
      networkType: (navigator as any).connection
        ? (navigator as any).connection.effectiveType
        : 'Not available',
      screenResolution: {
        width: window.screen.width,
        height: window.screen.height,
        pixelRatio: window.devicePixelRatio,
      },
    };

    return {
      ...event,
      extra: {
        ...event.extra,
        debugInfo,
      },
      tags: {
        ...event.tags,
        debug_mode: 'true',
        debug_session: 'true',
      },
    } as ErrorEvent;
  }

  static init() {
    if (environment.sentry) {
      sentryInit({
        dsn: 'https://6f8be92ab73f459a9de35f54449ebb01@o4505606794379264.ingest.sentry.io/4504685782237184',
        integrations: [
          httpClientIntegration({
            failedRequestStatusCodes: [400, 599],
            failedRequestTargets: [/(staging\.)?complicated\.life/],
          }),
        ],
        release: environment.sentryRelease,
        environment: environment.name,
        ignoreErrors: [
          'Non-Error exception captured',
          // Random plugins/extensions
          'top.GLOBALS',
          // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
          'originalCreateNotification',
          'canvas.contentDocument',
          'MyApp_RemoveAllHighlights',
          'http://tt.epicplay.com',
          "Can't find variable: ZiteReader",
          'jigsaw is not defined',
          'ComboSearch is not defined',
          'http://loading.retry.widdit.com/',
          'atomicFindClose',
          // Facebook borked
          'fb_xd_fragment',
          // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
          // reduce this. (thanks @acdha)
          // See http://stackoverflow.com/questions/4113268
          'bmi_SafeAddOnload',
          'EBCallBackMessageReceived',
          // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
          'conduitPage',
          // Ignore failed fetches to analytics domains, these can be blocked by browser extensions
          /Failed to fetch.*analytics\.google\.com/,
          /Failed to fetch.*usercentrics\.eu/,
          "Can't find variable: gmo",
          // Getstream timeouts
          'timeout of 10000ms exceeded',
          // random network errors
          'Failed to fetch',
        ],
        denyUrls: [
          // Facebook flakiness
          /graph\.facebook\.com/i,
          // Facebook blocked
          /connect\.facebook\.net\/en_US\/all\.js/i,
          // Woopra flakiness
          /eatdifferent\.com\.woopra-ns\.com/i,
          /static\.woopra\.com\/js\/woopra\.js/i,
          // Chrome extensions
          /extensions\//i,
          /^chrome:\/\//i,
          // Other plugins
          /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
          /webappstoolbarba\.texthelp\.com\//i,
          /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
          //  analytics errors, this happens when blocked
          'analytics.google.com',
          'usercentrics.eu',
        ],
        beforeSend(event: ErrorEvent) {
          return MonitoringService.enrichEventWithDebugInfo(event);
        },
        maxBreadcrumbs: MonitoringService.isDebugMode() ? 100 : 30,
      });
    }
  }

  static createGlobalErrorHandler() {
    if (environment.sentry) {
      return createErrorHandler({
        showDialog: environment.production === false,
      });
    } else {
      return new DevErrorHandler();
    }
  }

  static setUser(user: User) {
    if (environment.sentry) {
      sentrySetUser({
        id: `${user.id}`,
        email: user.email,
      });
    }
  }

  static clearUser() {
    if (environment.sentry) {
      sentrySetUser(null);
    }
  }

  static captureException(exception: any, hint?: CaptureContext) {
    if (environment.sentry) {
      const context: CaptureContext = {
        tags: MonitoringService.isDebugMode()
          ? {
              debug_mode: 'true',
              debug_session: 'true',
            }
          : undefined,
        ...hint,
      };

      sentryCaptureException(exception, context);
    } else {
      console.error('[ERROR (captured)]', exception);
    }
  }

  static captureMessage(
    message: string,
    captureContext?: CaptureContext | SeverityLevel
  ) {
    if (environment.sentry) {
      if (typeof captureContext === 'string') {
        sentryCaptureMessage(message, captureContext);
      } else {
        const context: CaptureContext = {
          tags: MonitoringService.isDebugMode()
            ? {
                debug_mode: 'true',
                debug_session: 'true',
              }
            : undefined,
          ...captureContext,
        };

        sentryCaptureMessage(message, context);
      }
    } else {
      console.error('[MESSAGE (captured)]', message);
    }
  }
}

class DevErrorHandler implements ErrorHandler {
  handleError(error) {
    const data = error ? error.originalError || error : 'Unknown error';
    console.error('[ERROR (global)]', data);
  }
}
