<h2 mat-dialog-title>{{ 'support.menu.title' | translate }}</h2>
<mat-dialog-content>
  {{ 'support.menu.message' | translate }}
  <div class="columns is-mobile has-margin-top-15">
    <div class="column has-text-centered">
      <a mat-button [href]="'support.menu.help_center_url' | translate" target="_blank" class="button">
        {{ 'support.menu.help_center' | translate }}
      </a>
    </div>
    <div class="column has-text-centered">
      <a mat-button [href]="'support.menu.submit_bug_report_url' | translate" target="_blank" class="button">
        {{ 'support.menu.submit_bug_report' | translate }}
      </a>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close class="button" cdkFocusInitial>{{ 'common.close' | translate }}</button>
</mat-dialog-actions>
